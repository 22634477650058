"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function provisionOperationStatusDetailsStart(actionContext) {
  actionContext.dispatch('PROV_OP_STATUS_DETAILS_BEGIN');
}

provisionOperationStatusDetailsStart.displayName = 'provisionOperationStatusDetailsStart';

function provisionOperationStatusDetailsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('PROV_OP_STATUS_DETAILS_FINISH', response.data);
}

provisionOperationStatusDetailsFinish.displayName = 'provisionOperationStatusDetailsFinish';

function provisionOperationStatusDetailsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('PROV_OP_STATUS_DETAILS_ERROR', response);
}

provisionOperationStatusDetailsError.displayName = 'provisionOperationStatusDetailsError';

var _default = function _default() {
  return {
    serviceName: 'getProvisionOperationStatusDetails',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/provision/operation/status/details/{taskIdentifier}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: provisionOperationStatusDetailsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: provisionOperationStatusDetailsFinish,
    // Handles all successful requests.
    onFailed: provisionOperationStatusDetailsError,
    // Handles all 400 level responses.
    onError: provisionOperationStatusDetailsError // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;