"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var showModalAction = function showModalAction(actionContext, payload) {
  actionContext.dispatch('SHOW_MODAL', payload);
};

showModalAction.displayName = 'showModalAction';
var _default = showModalAction;
exports["default"] = _default;