"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getExportDetailsStart(actionContext) {
  actionContext.dispatch('GET_EXPORT_OPERATION_DETAILS_START');
}

getExportDetailsStart.displayName = 'getExportDetailsStart';

function getExportDetailsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_EXPORT_OPERATION_DETAILS_FINISH', response.data);
}

getExportDetailsFinish.displayName = 'getExportDetailsFinish';

function getExportDetailsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_EXPORT_OPERATION_DETAILS_ERROR', response);
}

getExportDetailsError.displayName = 'getExportDetailsError';

var _default = function _default() {
  return {
    serviceName: 'getExportDetails',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/transfer/export'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getExportDetailsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getExportDetailsFinish,
    // Handles all successful requests.
    onFailed: getExportDetailsError,
    onError: getExportDetailsError,
    onTimeout: getExportDetailsError,
    // Handles a request timeout.
    onFatal: getExportDetailsError // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;