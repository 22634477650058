"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  headerBody: 'Tenant Id',
  cellValuePath: 'tenantId',
  sortable: true
}, {
  headerBody: 'Tenant Code',
  cellValuePath: 'tenantCode',
  sortable: true
}, {
  headerBody: 'Tenant Name',
  cellValuePath: 'tenantName',
  sortable: true
}, {
  headerBody: 'Pending Operation?',
  cellValuePath: 'pendingOperations',
  sortable: true,
  customValue: true
}, {
  headerBody: 'Active',
  cellValuePath: 'active',
  sortable: false,
  renderCell: function renderCell(cellValue) {
    return cellValue === true ? "true" : "false";
  }
}];
exports["default"] = _default;