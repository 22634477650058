"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _services = require("../../services");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default(config) {
  var contextConfiguration = config;
  contextConfiguration.services = (0, _services.createMockServiceConfiguration)((0, _get["default"])(config, 'services', {}));
  return contextConfiguration;
};

exports["default"] = _default;