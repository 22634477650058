"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _resourceTypes = _interopRequireDefault(require("./resource-types"));

var _interpolationTokens = _interopRequireDefault(require("./interpolation-tokens"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _resourceTypes["default"])(), (0, _interpolationTokens["default"])()];
};

exports["default"] = _default;