"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function beginProvOpHistorRaw(actionContext) {
  actionContext.dispatch('PROV_OP_HISTORY_RAW_BEGIN');
}

beginProvOpHistorRaw.displayName = 'beginProvOpHistorRaw';

function successProvOpHistoryRaw(context, _ref) {
  var response = _ref.response;
  context.dispatch('PROV_OP_HISTORY_RAW_SUCCESS', response.data);
}

successProvOpHistoryRaw.displayName = 'successProvOpHistoryRaw';

function errorProvOpHistoryRaw(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('PROV_OP_HISTORY_RAW_ERROR', response);
}

errorProvOpHistoryRaw.displayName = 'errorProvOpHistoryRaw';

var _default = function _default() {
  return {
    serviceName: 'getProvisionOperationHistoryRaw',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/provision/operation/history/raw'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: beginProvOpHistorRaw,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: successProvOpHistoryRaw,
    // Handles all successful requests.
    onFailed: errorProvOpHistoryRaw,
    // Handles all 400 level responses.
    onError: errorProvOpHistoryRaw // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;