"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function errorProvOpTenantHistory(actionContext, _ref) {
  var response = _ref.response;
  actionContext.dispatch('PROV_OP_HISTORY_TENANT_ERROR', response);
}

errorProvOpTenantHistory.displayName = 'errorProvOpTenantHistory';

var _default = function _default() {
  return {
    serviceName: 'getProvisionOperationTenantHistory',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/provision/operation/tenant/history?tenantId={tenantId}&offset={offset}&limit={limit}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: function beforeRequest(actionContext) {
      actionContext.dispatch('PROV_OP_HISTORY_TENANT_BEGIN');
    },
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: function onSuccess(context, _ref2) {
      var response = _ref2.response;
      context.dispatch('PROV_OP_HISTORY_TENANT_SUCCESS', response.data);
    },
    // Handles all successful requests.
    onFailed: errorProvOpTenantHistory,
    // Handles all 400 level responses.
    onError: errorProvOpTenantHistory,
    // Handles all 500 level responses.
    onTimeout: errorProvOpTenantHistory,
    // Handles a request timeout.
    onFatal: errorProvOpTenantHistory // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;