"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function errorCreatePoolsResources(actionContext, _ref) {
  var response = _ref.response;
  actionContext.dispatch('CREATE_POOLS_RESOURCE_ERROR', response);
}

errorCreatePoolsResources.displayName = 'errorCreatePoolsResource';

var _default = function _default() {
  return {
    /*
           * The service name will be used to invoke the service from either the component or action contexts.
           * Component: this.context.service.example
           * Action: actionContext.service.example
           */
    serviceName: 'createResourceSqlDb',
    authenticate: true,

    /*
           * The config is the vanilla axios configuration object, you can see all possible values on the axios documentation.
           */
    config: {
      method: 'POST',
      authenticate: true,
      // When set to true, will pass the auth_token as a Bearer token in the request header.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },

      /*
                * The gateway is static, we are defining the route here. Services should be deployed to the same domain.
                * Discuss service deployments with Sandeep.
                *
                * Dynamic segements: dynamic values will be mapped into the route by the plugin if the value is present in the options map
                * that is passed into the service call.
                */
      url: '/tenantsvcs/v1/api/infrastructure/{dbTypeApiName}/{testOrNot}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: function beforeRequest(actionContext) {
      actionContext.dispatch('CREATE_POOLS_RESOURCE_START');
    },
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: function onSuccess(actionContext, _ref2) {
      var response = _ref2.response;

      if (response.config.url.includes('test')) {
        if (response.data.success) {
          actionContext.dispatch('CREATE_POOLS_RESOURCE_TEST_FINISH', true);
        } else {
          actionContext.dispatch('CREATE_POOLS_RESOURCE_ERROR', response);
        }
      } else {
        actionContext.dispatch('CREATE_POOLS_RESOURCE_FINISH', response.data);
      }
    },
    // Handles all successful requests.
    onFailed: errorCreatePoolsResources,
    // Handles all 400 level responses.
    onError: errorCreatePoolsResources // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;