"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getTenantSubscriptionsStart(actionContext) {
  actionContext.dispatch('GET_TENANT_SUBSCRIPTION_START');
}

getTenantSubscriptionsStart.displayName = 'getTenantSubscriptionsStart';

function getTenantSubscriptionsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_TENANT_SUBSCRIPTION_FINISH', response.data);
}

getTenantSubscriptionsFinish.displayName = 'getTenantSubscriptionsFinish';

function getTenantSubscriptionsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_TENANT_SUBSCRIPTION_ERROR', response);
}

getTenantSubscriptionsError.displayName = 'getTenantSubscriptionsError';

var _default = function _default() {
  return {
    serviceName: 'getTenantSubscriptions',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/tenantapp/tenant/{tenantId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getTenantSubscriptionsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getTenantSubscriptionsFinish,
    // Handles all successful requests.
    onFailed: getTenantSubscriptionsError,
    // Handles all 400 level responses.
    onError: getTenantSubscriptionsError // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;