"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "createApplicationContext", {
  enumerable: true,
  get: function get() {
    return _applicationContext["default"];
  }
});
Object.defineProperty(exports, "createContextConfiguration", {
  enumerable: true,
  get: function get() {
    return _contextConfiguration["default"];
  }
});

var _applicationContext = _interopRequireDefault(require("./application-context"));

var _contextConfiguration = _interopRequireDefault(require("./context-configuration"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }