"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function pendingTenantInitSubscriptionAllTenantsStart(actionContext) {
  actionContext.dispatch('PENDING_TENANTINIT_SUBSCRIPTION_ALL_START');
}

pendingTenantInitSubscriptionAllTenantsStart.displayName = 'pendingTenantInitSubscriptionAllTenantsStart';

function pendingTenantInitSubscriptionAllTenantsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('PENDING_TENANTINIT_SUBSCRIPTION_ALL_FINISH', response.data);
}

pendingTenantInitSubscriptionAllTenantsFinish.displayName = 'pendingTenantInitSubscriptionAllTenantsFinish';

function pendingTenantInitSubscriptionAllTenantsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('PENDING_TENANTINIT_SUBSCRIPTION_ALL_ERROR', response);
}

pendingTenantInitSubscriptionAllTenantsError.displayName = 'pendingTenantInitSubscriptionAllTenantsError';

var _default = function _default() {
  return {
    serviceName: 'getPendingTenantInitSubscriptionsForAllTenants',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/tenantapp/pending/subscription/tenantinitialization'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: pendingTenantInitSubscriptionAllTenantsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: pendingTenantInitSubscriptionAllTenantsFinish,
    // Handles all successful requests.
    onFailed: pendingTenantInitSubscriptionAllTenantsError,
    // Handles all 400 level responses.
    onError: pendingTenantInitSubscriptionAllTenantsError // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;