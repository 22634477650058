"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  infrastructure: {
    pool: {
      description: 'A pool is a collection of infrastructure resources (like an RDS instance) usable by the provisioning system. Allows the ability for provisioning templates to specify a type of resource rather than a specific one so that additional resources can be added at any time. This allows us to spread load across multiple resource instances.',
      create: 'A pool is going to create a collection of resources of the same resourceType like an RDS instance. When filling out the details to create a new pool, be mindful of this.'
    },
    resource: {
      description: 'Resources belongs to a pool that is in turn used by the provisioning system. A pool can have a collection of resources that matches the same resource type as that of the pool.',
      create: 'An instance of a resource wil be created for the pool that will be used by the provisioning system.',
      toggleLock: 'Once you lock a resource, it can no longer be used for new provisioning task unless a tenat has already been provisioned on that resource. Locking a resource will also prevent new tenants from using that resource.'
    },
    tenant: {
      description: 'Defining inidividual tenant allows us to have individual configuration and application subscriptions for each tenant while sharing resources with other tenants.'
    }
  }
};
exports["default"] = _default;