"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function downloadExportStart(actionContext) {
  actionContext.dispatch('DOWNLOAD_EXPORT_START');
}

downloadExportStart.displayName = 'downloadExportStart';

function downloadExportFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('DOWNLOAD_EXPORT_FINISH', {
    data: response.data,
    headers: response.headers
  });
}

downloadExportFinish.displayName = 'downloadExportFinish';

function downloadExportError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('DOWNLOAD_EXPORT_ERROR', response);
}

downloadExportError.displayName = 'downloadExportError';

var _default = function _default() {
  return {
    serviceName: 'downloadExport',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/transfer/export/{exportId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: downloadExportStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: downloadExportFinish,
    // Handles all successful requests.
    onFailed: downloadExportError,
    onError: downloadExportError,
    onTimeout: downloadExportError,
    // Handles a request timeout.
    onFatal: downloadExportError // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;