"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  headerBody: 'Resource Pool Code',
  cellValuePath: 'resourcePoolCode',
  sortable: true
}, {
  headerBody: 'Resource Type',
  cellValuePath: 'resourceType',
  sortable: true
}, {
  headerBody: 'Friendly Name',
  cellValuePath: 'friendlyName',
  sortable: true
}, {
  headerBody: 'Pool Description',
  cellValuePath: 'poolDescription',
  sortable: true
}];
exports["default"] = _default;