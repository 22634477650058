"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _merge = _interopRequireDefault(require("lodash/merge"));

var _endpoints = _interopRequireDefault(require("../endpoints"));

var _provisionTemplateDefinition = _interopRequireDefault(require("./provision-template-definition"));

var _create = _interopRequireDefault(require("./provision-template-definition/create"));

var _activate = _interopRequireDefault(require("./provision-template-definition/activate"));

var _update = _interopRequireDefault(require("./provision-template-definition/update"));

var _provisionTemplate = _interopRequireDefault(require("./provision-template"));

var _detail = _interopRequireDefault(require("./provision-template/detail"));

var _updateContent = _interopRequireDefault(require("./provision-template/update-content"));

var _updateMetadata = _interopRequireDefault(require("./provision-template/update-metadata"));

var _updateConfiguration = _interopRequireDefault(require("./provision-template/update-configuration"));

var _delete = _interopRequireDefault(require("./provision-template/delete"));

var _create2 = _interopRequireDefault(require("./provision-template/create"));

var _allowedTemplatePools = _interopRequireDefault(require("./provision-template/allowed-template-pools"));

var _provisionOperation = _interopRequireDefault(require("./provision-operation"));

var _apiDatatypes = _interopRequireDefault(require("./api-datatypes"));

var _applicationConfiguration = _interopRequireDefault(require("./application-configuration"));

var _applicationDefinitionService = _interopRequireDefault(require("./application-definition-service"));

var _applicationFeatureSettings = _interopRequireDefault(require("./application-feature-settings"));

var _exampleService = _interopRequireDefault(require("./example-service"));

var _tenant = _interopRequireDefault(require("./tenant"));

var _infrastructureResource = _interopRequireDefault(require("./infrastructure-resource"));

var _tenantSubscription = _interopRequireDefault(require("./tenant-subscription"));

var _promotion = _interopRequireDefault(require("./promotion"));

var _getUiConfig = _interopRequireDefault(require("./config/get-ui-config"));

var _getEnvUiConfig = _interopRequireDefault(require("./config/get-env-ui-config"));

var _import = _interopRequireDefault(require("./import"));

var _export = _interopRequireDefault(require("./export"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var DEFAULT_CONFIG = {
  endpoints: _endpoints["default"]
};

var _default = function _default(config) {
  var configuration = (0, _merge["default"])(DEFAULT_CONFIG, config); // eslint-disable-line

  return [].concat(_toConsumableArray((0, _tenant["default"])()), [(0, _getUiConfig["default"])(), (0, _provisionTemplateDefinition["default"])(), (0, _exampleService["default"])(), (0, _create["default"])(), (0, _activate["default"])(), (0, _update["default"])(), (0, _provisionTemplate["default"])(), (0, _detail["default"])(), (0, _updateContent["default"])(), (0, _updateMetadata["default"])(), (0, _updateConfiguration["default"])(), (0, _delete["default"])(), (0, _create2["default"])(), (0, _allowedTemplatePools["default"])()], _toConsumableArray((0, _provisionOperation["default"])()), _toConsumableArray((0, _tenantSubscription["default"])()), _toConsumableArray((0, _infrastructureResource["default"])()), _toConsumableArray((0, _applicationConfiguration["default"])()), _toConsumableArray((0, _apiDatatypes["default"])()), _toConsumableArray((0, _applicationDefinitionService["default"])()), _toConsumableArray((0, _applicationFeatureSettings["default"])()), _toConsumableArray((0, _import["default"])()), _toConsumableArray((0, _export["default"])()), _toConsumableArray((0, _promotion["default"])()), [(0, _getEnvUiConfig["default"])()]);
};

exports["default"] = _default;