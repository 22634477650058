"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getExportOperationsStart(actionContext) {
  actionContext.dispatch('GET_EXPORT_OPERATIONS_START');
}

getExportOperationsStart.displayName = 'getExportOperationsStart';

function getExportOperationsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_EXPORT_OPERATIONS_FINISH', response.data);
}

getExportOperationsFinish.displayName = 'getExportOperationsFinish';

function getExportOperationsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_EXPORT_OPERATIONS_ERROR', response);
}

getExportOperationsError.displayName = 'getExportOperationsError';

var _default = function _default() {
  return {
    serviceName: 'getExportOperations',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/transfer/export?offset={offset}&limit={limit}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getExportOperationsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getExportOperationsFinish,
    // Handles all successful requests.
    onFailed: getExportOperationsError,
    onError: getExportOperationsError,
    onTimeout: getExportOperationsError,
    // Handles a request timeout.
    onFatal: getExportOperationsError // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;