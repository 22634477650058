"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _applicationDefStore = _interopRequireDefault(require("./application-def-store"));

var _provisionTemplateDefStore = _interopRequireDefault(require("./provision-template-def-store"));

var _provisionTemplateStore = _interopRequireDefault(require("./provision-template-store"));

var _pageStateStore = _interopRequireDefault(require("./page-state-store"));

var _modalStore = _interopRequireDefault(require("./modal-store"));

var _tenantStore = _interopRequireDefault(require("./tenant-store"));

var _exampleStore = _interopRequireDefault(require("./example-store"));

var _routeParamStore = _interopRequireDefault(require("./route-param-store"));

var _infrastructureResourceStore = _interopRequireDefault(require("./infrastructure-resource-store"));

var _provisionOperationSlideinStateStore = _interopRequireDefault(require("./provision-operation-slidein-state-store"));

var _tenantSubscriptionStore = _interopRequireDefault(require("./tenant-subscription-store"));

var _provisionOperationStore = _interopRequireDefault(require("./provision-operation-store"));

var _applicationConfigStore = _interopRequireDefault(require("./application-config-store"));

var _testTenantStore = _interopRequireDefault(require("./test-tenant-store"));

var _apiDatatypeStore = _interopRequireDefault(require("./api-datatype-store"));

var _appSubscriptionConfigStore = _interopRequireDefault(require("./app-subscription-config-store"));

var _importStore = _interopRequireDefault(require("./import-store"));

var _exportStore = _interopRequireDefault(require("./export-store"));

var _promotionStore = _interopRequireDefault(require("./promotion-store"));

var _uiConfigStore = _interopRequireDefault(require("./ui-config-store"));

var _appFeatureSettingStore = _interopRequireDefault(require("./app-feature-setting-store"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var stores = [_tenantStore["default"], _applicationDefStore["default"], _modalStore["default"], _exampleStore["default"], _provisionTemplateDefStore["default"], _routeParamStore["default"], _infrastructureResourceStore["default"], _provisionTemplateStore["default"], _pageStateStore["default"], _provisionOperationSlideinStateStore["default"], _tenantSubscriptionStore["default"], _provisionOperationStore["default"], _applicationConfigStore["default"], _testTenantStore["default"], _apiDatatypeStore["default"], _appSubscriptionConfigStore["default"], _importStore["default"], _exportStore["default"], _promotionStore["default"], _uiConfigStore["default"], _appFeatureSettingStore["default"]];
var _default = stores;
exports["default"] = _default;