"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var showProvisionOperationSlideInAction = function showProvisionOperationSlideInAction(actionContext, data) {
  actionContext.dispatch('SHOW_OPERATION_SLIDEIN', data);
};

showProvisionOperationSlideInAction.displayName = 'showProvisionOperationSlideInAction';
var _default = showProvisionOperationSlideInAction;
exports["default"] = _default;