"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function rejectImportStart(actionContext) {
  actionContext.dispatch('IMPORT_REJECT_START');
}

rejectImportStart.displayName = 'rejectImportStart';

function rejectImportFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('IMPORT_REJECT_FINISH', response.data);
}

rejectImportFinish.displayName = 'rejectImportFinish';

function rejectImportError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('IMPORT_REJECT_ERROR', response);
}

rejectImportError.displayName = 'rejectImportError';

var _default = function _default() {
  return {
    serviceName: 'rejectImport',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/transfer/import/{importId}/reject'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: rejectImportStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: rejectImportFinish,
    // Handles all successful requests.
    onFailed: rejectImportError,
    onError: rejectImportError,
    onTimeout: rejectImportError,
    // Handles a request timeout.
    onFatal: rejectImportError // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;