"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  headerBody: 'Order',
  cellValuePath: 'templateOrder',
  sortable: false
}, {
  headerBody: 'Type',
  cellValuePath: 'templateType',
  sortable: false
}, {
  headerBody: 'Application Id',
  cellValuePath: 'applicationId',
  sortable: false
}, {
  headerBody: 'Description',
  cellValuePath: 'description',
  sortable: false
}];
exports["default"] = _default;