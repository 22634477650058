"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getAppSubscriptionConfigFieldsStart(actionContext) {
  actionContext.dispatch('GET_APP_SUBSCRIPTION_CONFIG_FIELDS_START');
}

getAppSubscriptionConfigFieldsStart.displayName = 'getAppSubscriptionConfigFieldsStart';

function getAppSubscriptionConfigFieldsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_APP_SUBSCRIPTION_CONFIG_FIELDS_FINISH', response.data);
}

getAppSubscriptionConfigFieldsFinish.displayName = 'getAppSubscriptionConfigFieldsFinish';

function getAppSubscriptionConfigFieldsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_APP_SUBSCRIPTION_CONFIG_FIELDS_ERROR', response);
}

getAppSubscriptionConfigFieldsError.displayName = 'getAppSubscriptionConfigFieldsError';

var _default = function _default() {
  return {
    serviceName: 'getApplicationSubscriptionConfigFields',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/application/definition/subscriptionconfig/{applicationId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getAppSubscriptionConfigFieldsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getAppSubscriptionConfigFieldsFinish,
    // Handles all successful requests.
    onFailed: getAppSubscriptionConfigFieldsError,
    // Handles all 400 level responses.
    onError: getAppSubscriptionConfigFieldsError // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;