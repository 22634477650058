"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _createSqldb = _interopRequireDefault(require("./create-sqldb"));

var _createAwsenvironment = _interopRequireDefault(require("./create-awsenvironment"));

var _createDocdb = _interopRequireDefault(require("./create-docdb"));

var _createAwsSftp = _interopRequireDefault(require("./create-aws-sftp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _createSqldb["default"])(), (0, _createAwsenvironment["default"])(), (0, _createDocdb["default"])(), (0, _createAwsSftp["default"])()];
};

exports["default"] = _default;