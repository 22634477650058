"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function authenticateExample(actionContext, serviceInstance) {
  actionContext.dispatch('SAMPLE_AUTHENICATE_EVENT', serviceInstance.getId());
}

authenticateExample.displayName = 'authenticateExample'; // TODO: use this to pass full url / localhost

function beforeExample(actionContext) {
  actionContext.dispatch('RETRIEVE_EXAMPLE_START');
}

beforeExample.displayName = 'beforeExample';

function afterExample(actionContext, serviceInstance) {
  actionContext.dispatch('SAMPLE_AFTER_HOOK', serviceInstance.getId());
}

afterExample.displayName = 'afterExample';

function successExample(actionContext, _ref) {
  var response = _ref.response;
  actionContext.dispatch('RETRIEVE_EXAMPLE_FINISH', response.data);
}

successExample.displayName = 'successExample';

function failedExample(actionContext, serviceInstance) {
  actionContext.dispatch('SAMPLE_FAILED_EVENT', serviceInstance.getId());
}

failedExample.displayName = 'failedExample';

function errorExample(actionContext, serviceInstance) {
  actionContext.dispatch('SAMPLE_ERROR_EVENT', serviceInstance.getId());
}

errorExample.displayName = 'errorExample';

function timeoutExample(actionContext, serviceInstance) {
  actionContext.dispatch('SAMPLE_TIMEOUT_EVENT', serviceInstance.getId());
}

timeoutExample.displayName = 'timeoutExample';

function fatalExample(actionContext, serviceInstance) {
  actionContext.dispatch('SAMPLE_FATAL_EVENT', serviceInstance.getId());
}

fatalExample.displayName = 'fatalExample';

var _default = function _default() {
  //throw 'trace me';
  return {
    /*
     * The service name will be used to invoke the service from either the component or action contexts.
     * Component: this.context.service.example
     * Action: actionContext.service.example
     */
    serviceName: 'example',
    authenticate: true,

    /*
     * The config is the vanilla axios configuration object, you can see all possible values on the axios documentation.
     */
    config: {
      method: 'POST',
      authenticate: true,
      // When set to true, will pass the auth_token as a Bearer token in the request header.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },

      /*
       * The gateway is static, we are defining the route here. Services should be deployed to the same domain.
       * Discuss service deployments with Sandeep.
       *
       * Dynamic segements: dynamic values will be mapped into the route by the plugin if the value is present in the options map
       * that is passed into the service call.
       */
      url: '/tenantsvcs/v1/api/application/definition'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: beforeExample,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: successExample // Handles all successful requests.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;