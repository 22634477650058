"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isJsonMatchingSchema = isJsonMatchingSchema;
exports.isJsonSchemaValid = isJsonSchemaValid;
exports.isValidBase64Json = isValidBase64Json;

var _ajv = _interopRequireDefault(require("ajv"));

var _buffer = require("buffer");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function isValidBase64Json(value) {
  try {
    JSON.parse(_buffer.Buffer.from(value, 'base64').toString('ascii'));
    return true;
  } catch (e) {
    return false;
  }
}

function isJsonMatchingSchema(base64Json, base64Schema) {
  try {
    var schemaVal = JSON.parse(_buffer.Buffer.from(base64Schema, 'base64').toString('ascii'));
    var jsonVal = JSON.parse(_buffer.Buffer.from(base64Json, 'base64').toString('ascii'));
    var ajv = new _ajv["default"]();
    var validate = ajv.compile(schemaVal);
    var valid = validate(jsonVal);

    if (!valid) {
      console.log(validate.errors);
      return false;
    }

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

function isJsonSchemaValid(base64Schema) {
  var schemaVal = JSON.parse(_buffer.Buffer.from(base64Schema, 'base64').toString('ascii'));
  var ajv = new _ajv["default"]();
  var validate = ajv.compile(schemaVal);

  if (validate) {
    console.log(validate.errors);
    return false;
  }

  return true;
}