"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _get = _interopRequireDefault(require("./get"));

var _details = _interopRequireDefault(require("./details"));

var _runExport = _interopRequireDefault(require("./run-export"));

var _download = _interopRequireDefault(require("./download"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _get["default"])(), (0, _details["default"])(), (0, _runExport["default"])(), (0, _download["default"])()];
};

exports["default"] = _default;