"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCode = formatCode;
exports.noSpecialChars = noSpecialChars;

var _isNil = _interopRequireDefault(require("lodash/isNil"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

//TODO: for some reason special characters don't get replace right away
function formatCode(value) {
  var formattedValue = null;

  if (!(0, _isNil["default"])(value)) {
    formattedValue = value.toUpperCase();
    formattedValue = formattedValue.replace(/[^a-zA-Z0-9 -]/g, '');
    formattedValue = formattedValue.replace(/\s/g, '');
  }

  return formattedValue;
}

function noSpecialChars(value) {
  var formattedValue = null;

  if (!(0, _isNil["default"])(value)) {
    formattedValue = value;
    formattedValue = formattedValue.replace(/[^a-zA-Z0-9 -]/g, '');
    formattedValue = formattedValue.replace(/\s/g, '');
  }

  return formattedValue;
}