"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _allAppFeatureSettingColll = _interopRequireDefault(require("./all-app-feature-setting-colll"));

var _createAppFeatureSettingColl = _interopRequireDefault(require("./create-app-feature-setting-coll"));

var _updateAppFeatureSettingColl = _interopRequireDefault(require("./update-app-feature-setting-coll"));

var _deleteAppFeatureSettingColl = _interopRequireDefault(require("./delete-app-feature-setting-coll"));

var _getAppFeatureSettingCollById = _interopRequireDefault(require("./get-app-feature-setting-coll-by-id"));

var _getAppFeatureSettingCollFields = _interopRequireDefault(require("./get-app-feature-setting-coll-fields"));

var _createAppFeatureSettingField = _interopRequireDefault(require("./create-app-feature-setting-field"));

var _updateAppFeatureSettingField = _interopRequireDefault(require("./update-app-feature-setting-field"));

var _deleteAppFeatureSettingField = _interopRequireDefault(require("./delete-app-feature-setting-field"));

var _appFeatureSettingOverview = _interopRequireDefault(require("./app-feature-setting-overview"));

var _getAppFeatureSettingCollByAppSingle = _interopRequireDefault(require("./get-app-feature-setting-coll-by-app-single"));

var _getAppFeatureSettingCollByAppTree = _interopRequireDefault(require("./get-app-feature-setting-coll-by-app-tree"));

var _appFeatureSettingConfigureView = _interopRequireDefault(require("./app-feature-setting-configure-view"));

var _appFeatureSettingConfigureTenantView = _interopRequireDefault(require("./app-feature-setting-configure-tenant-view"));

var _saveAppFeatureSettings = _interopRequireDefault(require("./save-app-feature-settings"));

var _appFeatureSettingValueHistory = _interopRequireDefault(require("./app-feature-setting-value-history"));

var _updateAppFeatureSettingBinaryDefaultField = _interopRequireDefault(require("./update-app-feature-setting-binary-default-field"));

var _getAppFeatureSettingBinaryDefaultFieldMetadata = _interopRequireDefault(require("./get-app-feature-setting-binary-default-field-metadata"));

var _getAppFeatureSettingBinaryDefaultFieldValue = _interopRequireDefault(require("./get-app-feature-setting-binary-default-field-value"));

var _getAppFeatureSettingBinaryFieldValue = _interopRequireDefault(require("./get-app-feature-setting-binary-field-value"));

var _updateAppFeatureSettingBinaryValue = _interopRequireDefault(require("./update-app-feature-setting-binary-value"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _allAppFeatureSettingColll["default"])(), (0, _createAppFeatureSettingColl["default"])(), (0, _updateAppFeatureSettingColl["default"])(), (0, _deleteAppFeatureSettingColl["default"])(), (0, _getAppFeatureSettingCollById["default"])(), (0, _getAppFeatureSettingCollFields["default"])(), (0, _createAppFeatureSettingField["default"])(), (0, _updateAppFeatureSettingField["default"])(), (0, _deleteAppFeatureSettingField["default"])(), (0, _appFeatureSettingOverview["default"])(), (0, _getAppFeatureSettingCollByAppSingle["default"])(), (0, _getAppFeatureSettingCollByAppTree["default"])(), (0, _appFeatureSettingConfigureView["default"])(), (0, _saveAppFeatureSettings["default"])(), (0, _appFeatureSettingConfigureTenantView["default"])(), (0, _appFeatureSettingValueHistory["default"])(), (0, _updateAppFeatureSettingBinaryDefaultField["default"])(), (0, _getAppFeatureSettingBinaryDefaultFieldMetadata["default"])(), (0, _getAppFeatureSettingBinaryDefaultFieldValue["default"])(), (0, _getAppFeatureSettingBinaryFieldValue["default"])(), (0, _updateAppFeatureSettingBinaryValue["default"])()];
};

exports["default"] = _default;