"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var routeParametersChangedAction = function routeParametersChangedAction(actionContext, routeParameters) {
  actionContext.dispatch("ROUTE_PARAMETER_CHANGED", routeParameters);
};

routeParametersChangedAction.displayName = 'routeParametersChangedAction';
var _default = routeParametersChangedAction;
exports["default"] = _default;