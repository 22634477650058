"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
//TODO: SS - move this to config
var _default = {
  sqlDatabaseTypes: [{
    key: "MySql",
    text: "MySql"
  }, {
    key: "Redshift",
    text: "Redshift"
  }],
  appConfigActionTypes: [{
    key: "UPSERT",
    text: "UPSERT"
  }, {
    key: "DELETE",
    text: "DELETE"
  }, {
    key: "OVERLAY",
    text: "OVERLAY"
  }],
  eksDeploymentOperationTypes: [{
    key: "APPLY",
    text: "APPLY"
  }, {
    key: "DELETE",
    text: "DELETE"
  }],
  eksHydrationOperationTypes: [{
    key: "NONE",
    text: "NONE"
  }, {
    key: "COPY_SECRET",
    text: "COPY_SECRET"
  }],
  eksRollbackActionTypes: [{
    key: "REVERT",
    text: "REVERT"
  }, {
    key: "NOACTION",
    text: "NOACTION"
  }],
  userManagementActivityTypes: [{
    key: "CREATE_USER",
    text: "CREATE_USER"
  }, {
    key: "SET_PERMISSIONS_ONLY",
    text: "SET_PERMISSIONS_ONLY"
  }, {
    key: "CHANGE_PASSWORD",
    text: "CHANGE_PASSWORD"
  }, {
    key: "DROP_USER",
    text: "DROP_USER"
  }],
  provisionTemplateTypes: [{
    key: "SqlDatabaseCreateDbAndUser",
    text: "SqlDatabaseCreateDbAndUser"
  }, {
    key: "SqlDatabaseOnlyCreateUser",
    text: "SqlDatabaseOnlyCreateUser"
  }, {
    key: "SqlDatabaseDeltaScript",
    text: "SqlDatabaseDeltaScript"
  }, {
    key: "SqlDatabaseDeltaScriptBackgroundTask",
    text: "SqlDatabaseDeltaScriptBackgroundTask"
  }, {
    key: "SqlDatabaseUpdateUser",
    text: "SqlDatabaseUpdateUser"
  }, {
    key: "SsmParameterUpsert",
    text: "SsmParameterUpsert"
  }, {
    key: "EnterpriseAuditInitial",
    text: "EnterpriseAuditInitial"
  }, {
    key: "S3BucketCreation",
    text: "S3BucketCreation"
  }, {
    key: "TenantServicesInit",
    text: "TenantServicesInit"
  }, {
    key: "DocDbCreateDbAndUser",
    text: "DocDbCreateDbAndUser"
  }, {
    key: "DocDbAddDbToExistingUser",
    text: "DocDbAddDbToExistingUser"
  }, {
    key: "EKSDeployment",
    text: "EKSDeployment"
  }, {
    key: "IAMPolicyAttachment",
    text: "IAMPolicyAttachment"
  }, {
    key: "IAMPolicyCreation",
    text: "IAMPolicyCreation"
  }, {
    key: "IAMRoleCreation",
    text: "IAMRoleCreation"
  }, {
    key: "AWSSFTPUserCreation",
    text: "AWSSFTPUserCreation"
  }],
  allowedEnvironmentPrefixes: [{
    key: "bsvcs-dev",
    text: "bsvcs-dev"
  }, {
    key: "bsvcs-integ",
    text: "bsvcs-integ"
  }, {
    key: "bsvcs-stage",
    text: "bsvcs-stage"
  }, {
    key: "bsvcs-prod",
    text: "bsvcs-prod"
  }, {
    key: "ens-dev",
    text: "ens-dev"
  }, {
    key: "ens-test",
    text: "ens-test"
  }],
  appFeatureSettingFieldType: [{
    text: "Text",
    key: "text"
  }, {
    text: "Number",
    key: "number"
  }, {
    text: "Boolean",
    key: "boolean"
  }, {
    text: "JSON",
    key: "json",
    isBase64Field: true
  }, {
    text: "Option",
    key: "option"
  }, {
    text: "Time",
    key: "time"
  }, {
    text: "Key Value Pair",
    key: "keyvaluepair",
    isBase64Field: true
  }, {
    text: "Binary (File)",
    key: "binary",
    isBinaryField: true
  }, {
    text: "Text Section",
    key: "textsection"
  }, {
    text: "S3 Bucket Lifecycle Rule",
    key: "s3BucketLifecycleRule",
    isBase64Field: true
  }, {
    text: "MSGREPO Message Types",
    key: "messagetypes",
    isBase64Field: true
  }, {
    text: "MSGREPO Translation Mapping",
    key: "msgRepoTranslationMapping",
    isBase64Field: true
  }, {
    text: "PMSVC Collector Config",
    key: "pmsvcCollectorConfig",
    isBase64Field: true
  }, {
    text: "PMSVC Message Sections Config",
    key: "pmsvcMessageSectionsConfig",
    isBase64Field: true
  }, {
    text: "PMSVC Events History Config",
    key: "pmsvcEventsHistoryConfig",
    isBase64Field: true
  }, {
    text: "PMSVC HL7 Config",
    key: "pmsvcHl7Config",
    isBase64Field: true
  }, {
    text: "PMSVC Summary Update Configuration",
    key: "pmsvcSummaryUpdateConfiguration",
    isBase64Field: true
  }, {
    text: "Textarea",
    key: "textarea"
  }, {
    text: "Visitalgo Field Specific Field Exclusions",
    key: "visitalgoFieldSpecificExclusions",
    isBase64Field: true
  }, {
    text: "Syndromic Config",
    key: "syndromicConfig",
    isBase64Field: true
  }]
};
exports["default"] = _default;