"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function closeModalAction(actionContext, payload) {
  actionContext.dispatch('MODAL_CLOSED', payload);
}

closeModalAction.displayName = 'closeModalAction';
var _default = closeModalAction;
exports["default"] = _default;