"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  headerBody: 'Application Id',
  cellValuePath: 'applicationId',
  sortable: true
}, {
  headerBody: 'Application Name',
  cellValuePath: 'applicationName',
  sortable: true
}, {
  headerBody: 'Application Code',
  cellValuePath: 'applicationCode',
  sortable: true
}, {
  headerBody: 'AppDomain Type',
  cellValuePath: 'appDomainType',
  sortable: true
}, {
  headerBody: 'Active',
  cellValuePath: 'active',
  sortable: true,
  renderCell: function renderCell(cellValue) {
    return cellValue === true ? "true" : "false";
  }
}];
exports["default"] = _default;