"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var customValidationErrorAction = function customValidationErrorAction(actionContext, errorObject) {
  actionContext.dispatch(errorObject.event, errorObject.error);
};

customValidationErrorAction.displayName = 'customValidationErrorAction';
var _default = customValidationErrorAction;
exports["default"] = _default;