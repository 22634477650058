"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
//TODO: table definitions should come from the store
var _default = [{
  headerBody: 'Friendly Name',
  cellValuePath: 'friendlyName',
  sortable: true
}, {
  headerBody: 'AWS Region',
  cellValuePath: 'awsRegion',
  sortable: false
}, {
  headerBody: 'Allows New Provisioning',
  cellValuePath: 'newProvisionsAllowed',
  sortable: false,
  renderCell: function renderCell(cellValue) {
    return cellValue === true ? "true" : "false";
  }
}, {
  headerBody: 'Modified On',
  cellValuePath: 'modifiedOn',
  sortable: false
}];
exports["default"] = _default;