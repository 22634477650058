"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  version: '0.0.1',
  schema: '0.0.1',
  configuration: {
    tenantsvc: {}
  }
};
exports["default"] = _default;