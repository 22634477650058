"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function interpolationTokensStart(actionContext) {
  actionContext.dispatch('INTERPOLATION_TOKENS_START');
}

interpolationTokensStart.displayName = 'interpolationTokensStart';

function interpolationTokensFinish(actionContext, _ref) {
  var response = _ref.response;
  actionContext.dispatch('INTERPOLATION_TOKENS_FINISH', response.data);
}

interpolationTokensFinish.displayName = 'interpolationTokensFinish';

function interpolationTokensError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('INTERPOLATION_TOKENS_ERROR', response);
}

interpolationTokensError.displayName = 'interpolationTokensError';

var _default = function _default() {
  return {
    /*
           * The service name will be used to invoke the service from either the component or action contexts.
           * Component: this.context.service.example
           * Action: actionContext.service.example
           */
    serviceName: 'getInterpolationTokens',
    authenticate: true,

    /*
           * The config is the vanilla axios configuration object, you can see all possible values on the axios documentation.
           */
    config: {
      method: 'GET',
      authenticate: true,
      // When set to true, will pass the auth_token as a Bearer token in the request header.
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/provision/template/interpolationTokens'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: interpolationTokensStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: interpolationTokensFinish,
    // Handles all successful requests.
    onFailed: interpolationTokensError,
    // Handles all 400 level responses.
    onError: interpolationTokensError // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;