"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _client = require("@audacious/client");

var _stores = _interopRequireDefault(require("./stores"));

var _config = require("./config");

var _services = require("./services");

var _application = _interopRequireDefault(require("./components/application"));

require("./styles.css");

require("./bootstrap-grid.min.css");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { AppContainer } from 'react-hot-loader';
// import Log from './utils/log';
require('./utils/FileSaver.min.js'); // used for console debugging
// eslint-disable-next-line no-undef


if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'tenant-platform:*');
}

var _default = function _default() {
  var app = new _client.Client({
    components: {
      Application: _application["default"]
    },
    stores: _stores["default"],
    context: (0, _config.createApplicationContext)(),
    services: (0, _services.createServiceConfiguration)()
  });

  function createContext(contextConfiguration) {
    var d = app.createContext((0, _config.createContextConfiguration)(contextConfiguration));
    return d;
  }

  return {
    createContext: createContext,
    getComponent: app.getComponent.bind(app)
  };
}; // const context = app.createContext(
// 	createContextConfiguration({
// 		services: {
// 			mock: {
// 				// process.env.USE_MOCKS driven from npm run start:dev:mock
// 				// eslint-disable-next-line no-undef
// 				enabled: process.env.USE_MOCKS ? true : false,
// 				// mock these services even when mock is disabled
// 				include: [
// 					// 'loadApplicationConfiguration',
// 					// 'loadApplicationLocalization',
// 					// 'authorize',
// 					// 'resume',
// 					// 'logout',
// 					'example'
// 				],
// 				// don't mock these services even when mock is enabled
// 				exclude: [],
// 				immediate: true,
// 			},
// 		},
// 	}),
// );
// Log.info(context);
// const Component = app.getComponent();
// window.AinqContext = context;
// ReactDOM.render(
// 	<AppContainer>
// 		<Component context={context.getComponentContext()} />
// 	</AppContainer>,
// 	document.getElementById('application'),
// );


exports["default"] = _default;