"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _get = _interopRequireDefault(require("./get"));

var _create = _interopRequireDefault(require("./create"));

var _activate = _interopRequireDefault(require("./activate"));

var _update = _interopRequireDefault(require("./update"));

var _appSubConfigSingle = _interopRequireDefault(require("./app-sub-config-single"));

var _appSubConfigInherited = _interopRequireDefault(require("./app-sub-config-inherited"));

var _createAppSubConfig = _interopRequireDefault(require("./create-app-sub-config"));

var _updateAppSubConfig = _interopRequireDefault(require("./update-app-sub-config"));

var _deleteAppSubConfig = _interopRequireDefault(require("./delete-app-sub-config"));

var _appSubConfigAll = _interopRequireDefault(require("./app-sub-config-all"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _get["default"])(), (0, _create["default"])(), (0, _activate["default"])(), (0, _update["default"])(), (0, _appSubConfigSingle["default"])(), (0, _appSubConfigInherited["default"])(), (0, _createAppSubConfig["default"])(), (0, _updateAppSubConfig["default"])(), (0, _deleteAppSubConfig["default"])(), (0, _appSubConfigAll["default"])()];
};

exports["default"] = _default;