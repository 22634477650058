"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getAppFeatureSettingConfigureTenantViewStart(actionContext) {
  actionContext.dispatch('GET_APP_FEATURE_SETTING_CONFIGURE_TENANT_START');
}

getAppFeatureSettingConfigureTenantViewStart.displayName = 'getAppFeatureSettingConfigureTenantViewStart';

function getAppFeatureSettingConfigureTenantViewFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_APP_FEATURE_SETTING_CONFIGURE_TENANT_FINISH', response.data);
}

getAppFeatureSettingConfigureTenantViewFinish.displayName = 'getAppFeatureSettingConfigureTenantViewFinish';

function getAppFeatureSettingConfigureTenantViewError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_APP_FEATURE_SETTING_CONFIGURE_TENANT_ERROR', response);
}

getAppFeatureSettingConfigureTenantViewError.displayName = 'getAppFeatureSettingConfigureTenantViewError';

var _default = function _default() {
  return {
    serviceName: 'getAppFeatureSettingConfigureTenantView',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/featuresettingdefinition/configure/{tenantId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getAppFeatureSettingConfigureTenantViewStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getAppFeatureSettingConfigureTenantViewFinish,
    // Handles all successful requests.
    onFailed: getAppFeatureSettingConfigureTenantViewError,
    // Handles all 400 level responses.
    onError: getAppFeatureSettingConfigureTenantViewError // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;