"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getProvisionOperationLatestHistoryStart(actionContext) {
  actionContext.dispatch('PROV_OP_LATEST_HISTORY_BEGIN');
}

getProvisionOperationLatestHistoryStart.displayName = 'getProvisionOperationLatestHistoryStart';

function getProvisionOperationLatestHistoryFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('PROV_OP_LATEST_HISTORY_SUCCESS', response.data);
}

getProvisionOperationLatestHistoryFinish.displayName = 'getProvisionOperationLatestHistoryFinish';

function getProvisionOperationLatestHistoryError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('PROV_OP_LATEST_HISTORY_ERROR', response);
}

getProvisionOperationLatestHistoryError.displayName = 'getProvisionOperationLatestHistoryError';

var _default = function _default() {
  return {
    serviceName: 'getProvisionOperationLatestHistory',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/provision/operation/history/latest'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getProvisionOperationLatestHistoryStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getProvisionOperationLatestHistoryFinish,
    // Handles all successful requests.
    onFailed: getProvisionOperationLatestHistoryError,
    // Handles all 400 level responses.
    onError: getProvisionOperationLatestHistoryError // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;