"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getApplicationDefinitions;

function getApplicationDefinitions(_ref) {
  var resolve = _ref.resolve;
  var output = [{
    applicationId: 'APP_ID',
    applicationCode: 'APP_Code',
    applicationName: 'APP_NAME',
    parentApplicationId: null,
    locked: false,
    appDomainType: 'TenantInitialization',
    active: true,
    modifiedOn: '2020-05-25T21:51:41Z',
    modifiedBy: 'test',
    createdOn: '2020-05-25T21:51:14Z',
    createdBy: 'test'
  }, {
    applicationId: 'CONFIGALERT',
    applicationCode: 'CONFIGALERT',
    applicationName: 'Configuration Change Alert System',
    parentApplicationId: null,
    locked: false,
    appDomainType: 'TenantInitialization',
    active: true,
    modifiedOn: '2020-05-25T21:51:41Z',
    modifiedBy: 'test',
    createdOn: '2020-05-25T21:51:14Z',
    createdBy: 'test'
  }, {
    applicationId: 'ENS',
    applicationCode: 'ENS',
    applicationName: 'Encounter Notification Service',
    parentApplicationId: null,
    locked: false,
    appDomainType: 'Application',
    active: true,
    modifiedOn: '2020-05-04T17:08:13Z',
    modifiedBy: 'test',
    createdOn: '2020-05-04T17:07:59Z',
    createdBy: 'test'
  }, {
    applicationId: 'ENTAUDIT',
    applicationCode: 'ENTAUDIT',
    applicationName: 'Enterprise Audit (DEV)',
    parentApplicationId: null,
    locked: false,
    appDomainType: 'TenantInitialization',
    active: true,
    modifiedOn: '2020-05-22T18:01:22Z',
    modifiedBy: 'test',
    createdOn: '2020-05-22T18:00:29Z',
    createdBy: 'test'
  }, {
    applicationId: 'sabal',
    applicationCode: 'sabal',
    applicationName: 'sabal',
    parentApplicationId: 'sabal',
    locked: false,
    appDomainType: 'Application',
    active: false,
    modifiedOn: '2020-05-20T19:22:20Z',
    modifiedBy: 'test',
    createdOn: '2020-05-20T19:22:20Z',
    createdBy: 'test'
  }, {
    applicationId: 'SMARTALERTS',
    applicationCode: 'SA',
    applicationName: 'Smart Alerts',
    parentApplicationId: 'ENS',
    locked: false,
    appDomainType: 'Application',
    active: true,
    modifiedOn: '2020-05-04T17:10:46Z',
    modifiedBy: 'test',
    createdOn: '2020-05-04T17:10:38Z',
    createdBy: 'test'
  }, {
    applicationId: 'USERMGMT',
    applicationCode: 'USERMGMT',
    applicationName: 'User Management',
    parentApplicationId: null,
    locked: false,
    appDomainType: 'TenantInitialization',
    active: true,
    modifiedOn: '2020-05-04T17:09:54Z',
    modifiedBy: 'test',
    createdOn: '2020-05-04T17:09:44Z',
    createdBy: 'test'
  }];
  resolve(output);
}