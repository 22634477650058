"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getSubscriptionsRuntimePresetStart(actionContext) {
  actionContext.dispatch('GET_SUBSCRIPTION_RUNTIME_PRESET_VAR_START');
}

getSubscriptionsRuntimePresetStart.displayName = 'getSubscriptionsRuntimePresetStart';

function getSubscriptionsRuntimePresetFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_SUBSCRIPTION_RUNTIME_PRESET_VAR_FINISH', response.data);
}

getSubscriptionsRuntimePresetFinish.displayName = 'getSubscriptionsRuntimePresetFinish';

function getSubscriptionsRuntimePresetError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_SUBSCRIPTION_RUNTIME_PRESET_VAR_ERROR', response);
}

getSubscriptionsRuntimePresetError.displayName = 'getSubscriptionsRuntimePresetError';

var _default = function _default() {
  return {
    serviceName: 'getSubscriptionsRuntimePresetVariables',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/tenantapp/runtime/{tenantId}/{applicationId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getSubscriptionsRuntimePresetStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getSubscriptionsRuntimePresetFinish,
    // Handles all successful requests.
    onFailed: getSubscriptionsRuntimePresetError,
    // Handles all 400 level responses.
    onError: getSubscriptionsRuntimePresetError // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;