"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _merge = _interopRequireDefault(require("lodash/merge"));

var _applicationConfiguration = _interopRequireDefault(require("./application-configuration"));

var _applicationLocalization = _interopRequireDefault(require("./application-localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default(applicationContext) {
  var defaults = {
    applicationId: 'tenantsvcs',
    applicationName: 'AINQ Tenant Platform Configuration',
    defaultTenantCode: 'ainq',
    applicationConfiguration: _applicationConfiguration["default"],
    applicationLocalization: _applicationLocalization["default"]
  };
  return (0, _merge["default"])(defaults, applicationContext);
};

exports["default"] = _default;