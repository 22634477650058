"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  headerBody: 'Definition Id',
  cellValuePath: 'templateDefinitionId',
  sortable: true
}, {
  headerBody: 'Friendly Name',
  cellValuePath: 'friendlyName',
  sortable: true
}, {
  headerBody: 'Application Id',
  cellValuePath: 'applicationId',
  sortable: true
}, {
  headerBody: 'Active',
  cellValuePath: 'active',
  sortable: false,
  renderCell: function renderCell(cellValue) {
    return cellValue === true ? "true" : "false";
  }
}];
exports["default"] = _default;