"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _testSqldb = _interopRequireDefault(require("./test-sqldb"));

var _testAwsenvironment = _interopRequireDefault(require("./test-awsenvironment"));

var _testDocDb = _interopRequireDefault(require("./test-docDb"));

var _testAwsSftp = _interopRequireDefault(require("./test-aws-sftp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _testSqldb["default"])(), (0, _testAwsenvironment["default"])(), (0, _testDocDb["default"])(), (0, _testAwsSftp["default"])()];
};

exports["default"] = _default;