"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _localization = _interopRequireDefault(require("../../localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  version: '0.0.1',
  schema: '0.0.1',
  locale: 'en-US',
  configuration: {
    client: {},
    tenantsvc: _localization["default"]
  }
};
exports["default"] = _default;