"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _merge = _interopRequireDefault(require("lodash/merge"));

var _applicationDefService = _interopRequireDefault(require("./application-def-service.mock"));

var _exampleService = _interopRequireDefault(require("./example-service.mock"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var DEFAULT_MOCK_SERVICES = {
  getApplicationDefinitions: _applicationDefService["default"],
  example: _exampleService["default"]
};
/* These are the default mock service configurations.
 * enabled - When set to true will enable ALL mocks.
 * include - a list of service names which will ALWAYS be mocked, even if enabled is false.
 * exclude - a list of service names which will NEVER be mocked, even is enabled is true.
 * immediate - When set to false, ALL service request will prompt the user one how to proceed. Meant for DEBUG purposes. This does not require mocks to be enabled.
 */

var _default = function _default(_ref) {
  var _ref$enabled = _ref.enabled,
      enabled = _ref$enabled === void 0 ? false : _ref$enabled,
      _ref$include = _ref.include,
      include = _ref$include === void 0 ? [] : _ref$include,
      _ref$exclude = _ref.exclude,
      exclude = _ref$exclude === void 0 ? [] : _ref$exclude,
      _ref$immediate = _ref.immediate,
      immediate = _ref$immediate === void 0 ? true : _ref$immediate,
      _ref$services = _ref.services,
      services = _ref$services === void 0 ? {} : _ref$services;
  return {
    enabled: enabled,
    include: include,
    exclude: exclude,
    immediate: immediate,
    services: (0, _merge["default"])(DEFAULT_MOCK_SERVICES, services)
  };
};

exports["default"] = _default;