"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var routeParametersClearedAction = function routeParametersClearedAction(actionContext, routeParameters) {
  actionContext.dispatch("ROUTE_PARAMETER_CLEARED", routeParameters);
};

routeParametersClearedAction.displayName = 'routeParametersClearedAction';
var _default = routeParametersClearedAction;
exports["default"] = _default;