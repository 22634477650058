"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _history = _interopRequireDefault(require("./history"));

var _historyRaw = _interopRequireDefault(require("./history-raw"));

var _historyLatest = _interopRequireDefault(require("./history-latest"));

var _compiled = _interopRequireDefault(require("./compiled"));

var _plan = _interopRequireDefault(require("./plan"));

var _start = _interopRequireDefault(require("./start"));

var _undo = _interopRequireDefault(require("./undo"));

var _undoall = _interopRequireDefault(require("./undoall"));

var _testStart = _interopRequireDefault(require("./test-start"));

var _testUndo = _interopRequireDefault(require("./test-undo"));

var _status = _interopRequireDefault(require("./status"));

var _statusDetails = _interopRequireDefault(require("./status-details"));

var _taskLatest = _interopRequireDefault(require("./task-latest"));

var _running = _interopRequireDefault(require("./running"));

var _historyTenant = _interopRequireDefault(require("./history-tenant"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _history["default"])(), (0, _historyRaw["default"])(), (0, _compiled["default"])(), (0, _plan["default"])(), (0, _start["default"])(), (0, _undo["default"])(), (0, _testStart["default"])(), (0, _testUndo["default"])(), (0, _status["default"])(), (0, _statusDetails["default"])(), (0, _historyLatest["default"])(), (0, _taskLatest["default"])(), (0, _running["default"])(), (0, _historyTenant["default"])(), (0, _undoall["default"])()];
};

exports["default"] = _default;