"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _getByTenant = _interopRequireDefault(require("./get-by-tenant"));

var _getByTenantApp = _interopRequireDefault(require("./get-by-tenant-app"));

var _getByTenantAppFull = _interopRequireDefault(require("./get-by-tenant-app-full"));

var _getByTenantFull = _interopRequireDefault(require("./get-by-tenant-full"));

var _configAlertHistory = _interopRequireDefault(require("./config-alert-history"));

var _getByApp = _interopRequireDefault(require("./get-by-app"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _getByTenant["default"])(), (0, _getByTenantApp["default"])(), (0, _getByTenantAppFull["default"])(), (0, _getByTenantFull["default"])(), (0, _configAlertHistory["default"])(), (0, _getByApp["default"])()];
};

exports["default"] = _default;