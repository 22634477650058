"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _getTenantSubscriptions = _interopRequireDefault(require("./get-tenant-subscriptions"));

var _subscribeApp = _interopRequireDefault(require("./subscribe-app"));

var _unsubscribeApp = _interopRequireDefault(require("./unsubscribe-app"));

var _subscribeTenantinit = _interopRequireDefault(require("./subscribe-tenantinit"));

var _tenantSubscriptionHistory = _interopRequireDefault(require("./tenant-subscription-history"));

var _pendingProvOperationsTenant = _interopRequireDefault(require("./pending-prov-operations-tenant"));

var _pendingProvOperationsActive = _interopRequireDefault(require("./pending-prov-operations-active"));

var _pendingTenantinitSubAll = _interopRequireDefault(require("./pending-tenantinit-sub-all"));

var _pendingTenantinitSubSingle = _interopRequireDefault(require("./pending-tenantinit-sub-single"));

var _getSubscribedTenants = _interopRequireDefault(require("./get-subscribed-tenants"));

var _getPresetRuntimeVar = _interopRequireDefault(require("./get-preset-runtime-var"));

var _savePresetRuntimeVar = _interopRequireDefault(require("./save-preset-runtime-var"));

var _preventUndo = _interopRequireDefault(require("./prevent-undo"));

var _getConfiguredAppSubConfig = _interopRequireDefault(require("./get-configured-app-sub-config"));

var _saveConfiguredAppSubConfig = _interopRequireDefault(require("./save-configured-app-sub-config"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _getTenantSubscriptions["default"])(), (0, _subscribeApp["default"])(), (0, _unsubscribeApp["default"])(), (0, _subscribeTenantinit["default"])(), (0, _tenantSubscriptionHistory["default"])(), (0, _pendingTenantinitSubSingle["default"])(), (0, _pendingTenantinitSubAll["default"])(), (0, _pendingProvOperationsTenant["default"])(), (0, _getPresetRuntimeVar["default"])(), (0, _savePresetRuntimeVar["default"])(), (0, _pendingProvOperationsActive["default"])(), (0, _preventUndo["default"])(), (0, _getConfiguredAppSubConfig["default"])(), (0, _saveConfiguredAppSubConfig["default"])(), (0, _getSubscribedTenants["default"])()];
};

exports["default"] = _default;