"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createServiceConfiguration = exports.createMockServiceConfiguration = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _set = _interopRequireDefault(require("lodash/set"));

var _serviceConfigs = _interopRequireDefault(require("./service-configs"));

var _mock = _interopRequireDefault(require("./mock"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var createServiceConfiguration = function createServiceConfiguration(config) {
  return {
    global: (0, _get["default"])(config, 'global', {}),
    serviceConfigs: (0, _serviceConfigs["default"])(config)
  };
};

exports.createServiceConfiguration = createServiceConfiguration;

var createMockServiceConfiguration = function createMockServiceConfiguration(config) {
  var services = {};

  if ((0, _get["default"])(config, 'mock.enabled', false) || (0, _get["default"])(config, 'mock.include', [].length > 0)) {
    (0, _set["default"])(services, 'mock', (0, _mock["default"])((0, _get["default"])(config, 'mock', {})));
  }

  return services;
};

exports.createMockServiceConfiguration = createMockServiceConfiguration;