"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function retrieveInfrastructurePoolsError(actionContext, _ref) {
  var response = _ref.response;
  actionContext.dispatch('RETRIEVE_INFRASTRUCTURE_POOLS_ERROR', response);
}

retrieveInfrastructurePoolsError.displayName = 'retrieveInfrastructurePoolsError';

var _default = function _default() {
  return {
    serviceName: 'getInfrastructurePools',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/infrastructure/pool'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: function beforeRequest(actionContext) {
      actionContext.dispatch('RETRIEVE_INFRASTRUCTURE_POOLS_START');
    },
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: function onSuccess(context, _ref2) {
      var response = _ref2.response;
      context.dispatch('RETRIEVE_INFRASTRUCTURE_POOLS_FINISH', response.data);
    },
    // Handles all successful requests.
    onFailed: retrieveInfrastructurePoolsError,
    // Handles all 400 level responses.
    onError: retrieveInfrastructurePoolsError,
    // Handles all 500 level responses.
    onTimeout: retrieveInfrastructurePoolsError,
    // Handles a request timeout.
    onFatal: retrieveInfrastructurePoolsError // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;