"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function beforeProvTemplateContentUpdate(actionContext) {
  actionContext.dispatch('UPDATE_PROV_TEMP_CONTENT_START');
}

beforeProvTemplateContentUpdate.displayName = 'beforeProvTemplateContentUpdate';

function provTemplateContentUpdateSuccess(context, _ref) {
  var response = _ref.response;
  context.dispatch('UPDATE_PROV_TEMP_CONTENT_FINISH', response.data);
}

provTemplateContentUpdateSuccess.displayName = 'provTemplateContentUpdateSuccess';

function provTemplateContentUpdateError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('UPDATE_PROV_TEMP_CONTENT_ERROR', response);
}

provTemplateContentUpdateError.displayName = 'provTemplateContentUpdateError';

var _default = function _default() {
  return {
    serviceName: 'updateProvisionTemplateContents',
    authenticate: true,
    config: {
      method: 'PUT',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/provision/template/content/{templateId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: beforeProvTemplateContentUpdate,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: provTemplateContentUpdateSuccess,
    // Handles all successful requests.
    onFailed: provTemplateContentUpdateError,
    // Handles all 400 level responses.
    onError: provTemplateContentUpdateError // Handles all 500 level responses.
    // onFailed: failedExample, // Handles all 400 level responses.
    // onError: errorExample, // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;