"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _getTenants = _interopRequireDefault(require("./get-tenants"));

var _specificTenant = _interopRequireDefault(require("./specific-tenant"));

var _update = _interopRequireDefault(require("./update"));

var _create = _interopRequireDefault(require("./create"));

var _activateDeactivateTenant = _interopRequireDefault(require("./activateDeactivateTenant"));

var _getTenantHistory = _interopRequireDefault(require("./get-tenant-history"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _getTenants["default"])(), (0, _specificTenant["default"])(), (0, _update["default"])(), (0, _create["default"])(), (0, _activateDeactivateTenant["default"])(), (0, _getTenantHistory["default"])()];
};

exports["default"] = _default;