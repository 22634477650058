"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getAppFeatureSettingBinaryDefaultFieldValueStart(actionContext) {
  actionContext.dispatch('GET_APP_FEATURE_SETTING_BINARY_DEFAULT_FIELD_VALUE_START');
}

getAppFeatureSettingBinaryDefaultFieldValueStart.displayName = 'getAppFeatureSettingBinaryDefaultFieldValueStart';

function getAppFeatureSettingBinaryDefaultFieldValueFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_APP_FEATURE_SETTING_BINARY_DEFAULT_FIELD_VALUE_FINISH', response.data);
}

getAppFeatureSettingBinaryDefaultFieldValueFinish.displayName = 'getAppFeatureSettingBinaryDefaultFieldValueFinish';

function getAppFeatureSettingBinaryDefaultFieldValueError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_APP_FEATURE_SETTING_BINARY_DEFAULT_FIELD_VALUE_ERROR', response);
}

getAppFeatureSettingBinaryDefaultFieldValueError.displayName = 'getAppFeatureSettingBinaryDefaultFieldValueError';

var _default = function _default() {
  return {
    serviceName: 'getAppFeatureSettingBinaryDefaultFieldValue',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream'
      },
      url: '/tenantsvcs/v1/api/featuresettingdefinition/field/binary/download/{fieldId}'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getAppFeatureSettingBinaryDefaultFieldValueStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getAppFeatureSettingBinaryDefaultFieldValueFinish,
    // Handles all successful requests.
    onFailed: getAppFeatureSettingBinaryDefaultFieldValueError,
    // Handles all 400 level responses.
    onError: getAppFeatureSettingBinaryDefaultFieldValueError // Handles all 500 level responses.
    // onTimeout: timeoutExample, // Handles a request timeout.
    // onFatal: fatalExample, // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;