"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function getPendingPromotionsStart(actionContext) {
  actionContext.dispatch('GET_PENDING_PROMOTION_START');
}

getPendingPromotionsStart.displayName = 'getPendingPromotionsStart';

function getPendingPromotionsFinish(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_PENDING_PROMOTION_FINISH', response.data);
}

getPendingPromotionsFinish.displayName = 'getPendingPromotionsFinish';

function getPendingPromotionsError(actionContext, _ref2) {
  var response = _ref2.response;
  actionContext.dispatch('GET_PENDING_PROMOTION_ERROR', response);
}

getPendingPromotionsError.displayName = 'getPendingPromotionsError';

var _default = function _default() {
  return {
    serviceName: 'getPendingPromotions',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/tenantsvcs/v1/api/transfer/promote/pending'
    },
    // onAuthenticate: authenticateExample, // Hook that fires when autheticate is set to true.
    beforeRequest: getPendingPromotionsStart,
    // Hook that occurs at the very beginning of a service being invoked. Provides the means to manipulate the request.
    // afterRequest: afterExample, // Hook that at the very end of a service being invoked. Will fire regards of the result of the request that was made.
    onSuccess: getPendingPromotionsFinish,
    // Handles all successful requests.
    onFailed: getPendingPromotionsError,
    onError: getPendingPromotionsError,
    onTimeout: getPendingPromotionsError,
    // Handles a request timeout.
    onFatal: getPendingPromotionsError // Handles a client exception that occurs during a service call being invoked.

  };
};

exports["default"] = _default;