"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _get = _interopRequireDefault(require("./get"));

var _details = _interopRequireDefault(require("./details"));

var _prepare = _interopRequireDefault(require("./prepare"));

var _reject = _interopRequireDefault(require("./reject"));

var _import = _interopRequireDefault(require("./import"));

var _download = _interopRequireDefault(require("./download"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  return [(0, _get["default"])(), (0, _details["default"])(), (0, _prepare["default"])(), (0, _reject["default"])(), (0, _import["default"])(), (0, _download["default"])()];
};

exports["default"] = _default;