"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var hideProvisionOperationSlideInAction = function hideProvisionOperationSlideInAction(actionContext, data) {
  actionContext.dispatch('HIDE_OPERATION_SLIDEIN', data);
};

hideProvisionOperationSlideInAction.displayName = 'hideProvisionOperationSlideInAction';
var _default = hideProvisionOperationSlideInAction;
exports["default"] = _default;