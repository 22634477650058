"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var provisionOperationCompleteAction = function provisionOperationCompleteAction(actionContext, data) {
  actionContext.dispatch("PROVISION_OPERATION_COMPLETE", data);
};

provisionOperationCompleteAction.displayName = 'provisionOperationCompleteAction';
var _default = provisionOperationCompleteAction;
exports["default"] = _default;